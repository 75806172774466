import { core as log } from "./logger";

/** @param {import("msw/browser").StartOptions} startOptions */
export async function enableMocking(startOptions) {
  if (import.meta.env.MODE !== "development") return;
  if (!parseBoolean(import.meta.env.APPLY_MOCKING)) return;

  log("mocking enabled");
  const { worker } = await import("../mocks/browser");

  return worker.start(startOptions);
}

/** @param {unknown} value */
function parseBoolean(value) {
  if (typeof value === "boolean") return value;
  if (value === 1) return true;
  if (typeof value === "string") {
    switch (value.toLowerCase().trim()) {
      case "true":
      case "yes":
      case "1":
        return true;
    }
  }
  return false;
}

/** @param {string=} token  */
export function clearSession(token) {
  // Legacy client storage
  localStorage.removeItem("apply:session");
  localStorage.removeItem("apply:terms");

  // Clear session
  if (token) {
    localStorage.removeItem(`apply:${token}:session`);
    localStorage.removeItem(`apply:${token}:terms`);
  }

  document.location.reload();
}
