import { useRef, useState } from "react";

import Chat from "./components/Chat";
import Control from "./components/Control";
import Footer from "./components/Footer";
import Header from "./components/Header";
import TermsDialog from "./components/TermsDialog";
import { useApi } from "./contexts/ApiContext";
import { ChatProvider } from "./contexts/ChatContext";

/**
 *
 * @param {object} props
 * @param {boolean} props.close
 * @param {() => void} props.onClose
 * @param {boolean} props.isOpen
 * @param {boolean} props.isDrawer
 */
export default function App({ isOpen, close, onClose, isDrawer }) {
  const { api } = useApi();

  const getTermsAccepted = () => {
    try {
      // Migrate legacy terms acceptance
      const legacyTerms = localStorage.getItem("apply:terms");
      if (legacyTerms) {
        localStorage.setItem(`apply:${api.token}:terms`, legacyTerms);
        localStorage.removeItem("apply:terms");
      }

      return localStorage.getItem(`apply:${api.token}:terms`) === "accepted";
    } catch (err) {
      return false;
    }
  };

  const [termsAccepted, setTermsAccepted] = useState(() => getTermsAccepted());
  const [container, setContainer] = useState(null);
  const inputRef = useRef(null);

  const onAccept = () => {
    setTermsAccepted(true);
    setTimeout(() => {
      inputRef.current.focus();
    });
  };

  return (
    <>
      <div
        className="flex flex-col w-full h-full rounded-xl bg-gray-50"
        ref={setContainer}
      >
        <ChatProvider>
          <Header
            close={close}
            onClose={onClose}
            container={container}
            isDrawer={isDrawer}
          />
          <Chat />
          <Control disabled={!termsAccepted} ref={inputRef} />
          <Footer />
        </ChatProvider>
      </div>
      {isOpen && (
        <TermsDialog {...{ termsAccepted, onAccept, container, isDrawer }} />
      )}
    </>
  );
}
