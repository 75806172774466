import { useEffect, useRef } from "react";
import { Transition, TransitionGroup } from "react-transition-group";

import { useChat } from "../contexts/ChatContext";
import Message from "./Message";

export default function Chat() {
  const { messages, pending, retry } = useChat();
  const chatRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat when new messages are added
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages]);

  return (
    <div
      className="flex-1 flex flex-col-reverse gap-1.5 py-2 pl-4 pr-2 overflow-y-scroll scroller w-full max-w-sm mx-auto overscroll-contain"
      ref={chatRef}
    >
      <TransitionGroup component={null}>
        {messages.map((message, index) => {
          const isLast = index === 0;
          const isLastInGroup =
            index === 0 || message.role !== messages[index - 1].role;
          const url = message.meta_url_href
            ? {
                href: message.meta_url_href,
                label: message.meta_url_label,
              }
            : undefined;
          const isError = message.meta_error;

          return (
            <Transition
              key={message.uuid}
              nodeRef={message.nodeRef}
              timeout={150}
            >
              {(state) => (
                <Message
                  uuid={message.uuid}
                  body={message.body}
                  url={url}
                  isError={isError}
                  incoming={message.role === "bot"}
                  isLast={isLast}
                  isLastInGroup={isLastInGroup}
                  pending={message.role === "user" && isLast && pending}
                  transitionState={state}
                  handleRetry={retry}
                  ref={message.nodeRef}
                />
              )}
            </Transition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}
