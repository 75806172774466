import App from "@core/App";
import { CompanyAvatar } from "@core/components/Avatars";
import { useApi } from "@core/contexts/ApiContext";
import { useConfig } from "@core/contexts/ConfigContext";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Transition } from "react-transition-group";

import useIOS from "../hooks/useIOS";
import useMediaQuery from "../hooks/useMediaQuery";
import useMobile from "../hooks/useMobile";

const triggerText = "Chat and apply here!";

const mobileBreakpoint = "640px";

const supportsDvw = CSS.supports("width: 100dvw");
const supportsDvh = CSS.supports("height: 100dvh");

/**
 * @param {object=} options
 * @param {number=} options.zIndex
 * @param {number=} options.zIndexTrigger
 */
const getAnimatedStyles = ({ zIndex, zIndexTrigger } = {}) => {
  return {
    drawer: {
      margin: "0px",
      width: "350px",
      height: supportsDvh ? "100dvh" : "100vh",
      borderRadius: "0px",
      zIndex: zIndex ?? 10_000,
    },
    overlay: {
      margin: "0px",
      width: supportsDvw ? "100dvw" : "100vw",
      height: supportsDvh ? "100dvh" : "100vh",
      borderRadius: "0px",
      zIndex: zIndex ?? 10_000,
    },
    trigger: {
      margin: "20px",
      width: "200px",
      height: "50px",
      borderRadius: "12px",
      zIndex: zIndexTrigger ?? zIndex ?? 10_000,
    },
  };
};

/**
 * @param {boolean} isOpen
 * @param {object=} opts
 * @param {number=} opts.zIndex
 * @param {number=} opts.zIndexTrigger
 */
const useAnimatedStyles = (isOpen, opts) => {
  const isMobileWidth = useMediaQuery(
    `(max-width: ${parseInt(mobileBreakpoint)}px)`,
  );

  const animatedStyles = getAnimatedStyles(opts);

  if (!isOpen) {
    return { styles: animatedStyles.trigger, isFullscreen: false };
  }

  if (isMobileWidth) {
    return { styles: animatedStyles.overlay, isFullscreen: true };
  }

  return { styles: animatedStyles.drawer, isFullscreen: false };
};

/**
 * @param {('left'|'center'|'right')} position
 * @param {number} marginBottom
 * @param {number} marginX
 */
const getFixedStyles = (position, marginBottom, marginX) => {
  switch (position) {
    case "left":
      return {
        left: 0,
        marginBottom: `${parseInt(marginBottom)}px`,
        marginLeft: `${parseInt(marginX)}px`,
      };
    case "right":
      return {
        right: 0,
        marginBottom: `${parseInt(marginBottom)}px`,
        marginRight: `${parseInt(marginX)}px`,
      };
    default:
      return {
        left: "50%",
        transform: "translateX(-50%)",
        marginBottom: `${parseInt(marginBottom)}px`,
      };
  }
};

export default function Embedded() {
  const { session, fetchSession } = useApi();
  const { config } = useConfig();

  const dialogRef = useRef(null);
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen && !session) {
      fetchSession();
    }
  }, [isOpen, session, fetchSession]);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.show();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const { styles, isFullscreen } = useAnimatedStyles(isOpen, {
    zIndex: config?.z_index,
    zIndexTrigger: config?.z_index_trigger,
  });
  const isDrawer = isOpen && !isFullscreen;
  const fixedStyles = getFixedStyles("right", 20, 20);

  const iOS = useIOS();
  const isMobile = useMobile();

  useEffect(() => {
    const targetElement = dialogRef.current;

    // Body scroll locking breaks in-dialog scrolling on iOS.
    if (isOpen && isMobile && !iOS) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen, isMobile, iOS]);

  return (
    <div
      className={clsx(
        "fixed bottom-0 text-sm text-black bg-white rounded-xl border shadow-md motion-reduce:transition-none transition-[width,height] duration-300",
        isOpen && "overflow-clip",
      )}
      style={{
        ...styles,
        ...fixedStyles,
      }}
    >
      <dialog
        ref={dialogRef}
        className="absolute top-0 left-0 flex-col text-gray-900 bg-gray-50 open:flex"
        style={{
          width: styles.width,
          height: styles.height,
        }}
      >
        <App close onClose={handleClose} isOpen={isOpen} isDrawer={isDrawer} />
      </dialog>

      <Transition
        nodeRef={buttonRef}
        mountOnEnter
        unmountOnExit
        appear
        exit={false}
        in={!isOpen}
        timeout={1000}
      >
        {(state) => (
          <button
            onClick={handleOpen}
            className={clsx(
              "items-center rounded-xl justify-center flex flex-row w-full h-full gap-2 p-2 transition-opacity duration-300 focus:outline-none focus:ring-2 focus:ring-primary/40",
              {
                "opacity-100": state === "entering" || state === "entered",
                "opacity-0": state === "exited" || state === "exiting",
              },
            )}
            ref={buttonRef}
          >
            <span className="text-gray-700 text-md">{triggerText}</span>
            <CompanyAvatar config={config} />
          </button>
        )}
      </Transition>
    </div>
  );
}
