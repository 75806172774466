import { core as log } from "./logger";
import { request } from "./request";

export default class Api {
  static #base = import.meta.env.APPLY_API_URL;

  #token;
  #sid;
  #deploymentContext;

  /**
   * @param {string} token
   * @param {object} options
   * @param {string} options.deploymentContext
   * @param {string} options.sid
   */
  constructor(token, { deploymentContext, sid } = {}) {
    this.#token = token;
    this.#sid = sid;
    this.#deploymentContext = deploymentContext;
  }

  async getConfig(signal) {
    return await this.#get("config", signal);
  }

  async putSession(body, signal) {
    const result = await this.#put("session", body, signal);
    this.#sid = result.data.session.sid;

    localStorage.setItem(
      `apply:${this.#token}:session`,
      JSON.stringify({
        sid: result.data.session.sid,
        // last_seen: session.last_seen || null,
      }),
    );

    return result;
  }

  async getMessages(signal) {
    return await this.#get("messages", signal);
  }

  async postMessage(body, signal) {
    return await this.#post("messages", body, signal);
  }

  async acceptTerms(signal) {
    return await this.#post("chats/accept_terms", null, signal).then(() => {
      localStorage.setItem(`apply:${this.#token}:terms`, "accepted");
    });
  }

  static fromLocalStorage(token, { deploymentContext } = {}) {
    // Migrate legacy session data
    const legacyData = localStorage.getItem("apply:session");
    if (legacyData) {
      localStorage.removeItem("apply:session");
      localStorage.setItem(`apply:${token}:session`, legacyData);
    }

    const data = localStorage.getItem(`apply:${token}:session`);
    let sid = null;
    if (data !== null) {
      try {
        sid = JSON.parse(data)?.sid;
      } catch (err) {
        log("Failed to parse session data", err);
        localStorage.removeItem("apply:session");
      }
    }

    return new Api(token, { deploymentContext, sid });
  }

  get sid() {
    return this.#sid;
  }

  get token() {
    return this.#token;
  }

  get deploymentContext() {
    return this.#deploymentContext;
  }

  #get(path, signal) {
    return request(new URL(path, Api.#base), {
      method: "GET",
      headers: this.#headers(),
      signal,
    });
  }

  #put(path, body, signal) {
    return request(new URL(path, Api.#base), {
      method: "PUT",
      headers: this.#headers(),
      body,
      signal,
    });
  }

  #post(path, body, signal) {
    return request(new URL(path, Api.#base), {
      method: "POST",
      headers: this.#headers(),
      body,
      signal,
    });
  }

  #headers() {
    return {
      Authorization: `Bearer ${this.#token}`,
      ...(this.#deploymentContext && {
        "X-Deployment-Context": this.#deploymentContext,
      }),
      ...(this.#sid && { "X-Session-ID": this.#sid }),
    };
  }
}
