import contentType from "content-type";

export class HTTPError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

/**
 * Fetch JSON data from a URL.
 * Returns a promise that resolves to the parsed JSON data.
 * Rejects with:
 * - TypeError if the body payload cannot be serialized to JSON, or the request is invalid
 * - DOMException "AbortError" if the request is aborted
 * - DOMException "NotAllowedError" if browser permissions prevent the request
 * - SyntaxError if the response body is not valid JSON
 * - HTTPError if the response has an error status code
 * @param {string|URL|Request} resource
 * @param {object} options
 * @param {string} options.method
 * @param {object=} options.body
 * @param {object=} options.headers
 * @param {AbortSignal=} options.signal
 * @returns {Promise<{data: string|object; status: number}>}
 */
export async function request(
  resource,
  { method = "GET", body, headers, signal } = {},
) {
  // will throw TypeError if body cannot be serialized
  const jsonBody = JSON.stringify(body);

  // will throw DOMException "AbortError" if the request is aborted
  // will throw DOMException "NotAllowedError" if browser permissions prevent the request
  // will throw TypeError if the request is invalid
  const response = await fetch(resource, {
    method: method,
    headers: {
      ...headers,
      ...(jsonBody && { "Content-Type": "application/json; charset=utf-8" }),
    },
    body: jsonBody,
    signal,
  });

  // will throw a TypeError if the content type is invalid
  const typeHeader = response.headers.get("Content-Type");
  const type = typeHeader ? contentType.parse(typeHeader)?.type : null;

  let data = null;

  switch (type) {
    case "application/json":
      // will throw a SyntaxError if the body is not valid JSON
      data = await response.json();
      break;
    case "text/plain":
      data = await response.text();
      break;
    default:
      break;
  }

  // will throw an HTTPError if the response has an error status code
  if (!response.ok) {
    const message = data?.message || response.statusText;
    const status = response.status;
    throw new HTTPError(message, status, data);
  }

  return { data, status: response.status };
}
